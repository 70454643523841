.content_menu {
  display: flex;
  width: 280px;
  height:  calc(100vh - 17px);  
  padding: 12px;
  flex-direction: column; 
  justify-content: space-between;
  gap: 8px;
  position: absolute;
  left: 11px;
  top: 29px;
  border-radius: 42px 28px 28px 42px;
  border: 1.4px solid rgba(255, 255, 255, 0.40);
  background: var(--Windows-Glass, rgba(128, 128, 128, 0.30));
  background-blend-mode: luminosity; 
  backdrop-filter: blur(50px);
  z-index: 33333333; 
}

.content_menu_sub{
  display: flex;
  width: 100%;
  height:  calc(100vh - 100px);  
  padding: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 33333333; 
}

.menu_header {
  display: flex;
  height: 50px;  
  align-items: left;
  flex-shrink: 0;
  align-self: stretch;
  flex-direction: column;
}

.menu_section_header {
  display: flex;
  height: 35px;
  padding-left: 20px;
  padding-top: 10px;
  align-items: left;
   
}

.menu_separator {
  display: flex;
  padding: 0px 12px 8px 12px;
  align-items: flex-start; 
  width: 100%;
  align-self: stretch;
  border-bottom: 1px solid var(--Separators-Separator, rgba(255, 255, 255, 0.07));
}

.menu_section_header_text { 
  color: var(--Text-Secondary, rgba(255, 255, 255, 0.23));
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 146.154% */
}

.menu_item {
  display: flex;
  padding: 0px 15px 5px 20px;
  align-items: center;
  align-self: stretch;
  color: var(--Labels-Primary---Dark, #FFF);
 
}
.menu_item:hover {
  display: flex;
  padding: 0px 15px 5px 20px;
  align-items: center;
  align-self: stretch;
  color: var(--Labels-Primary---Dark, #FFF); 
  border-radius: 16px;
  background: var(--Controls-Hover, linear-gradient(0deg, rgba(58, 57, 57, 0.804) 0%, rgba(94, 94, 94, 0.06) 100%), rgba(255, 255, 255, 0.04));
  background-blend-mode: color-dodge, lighten;
}

.menu_item_title {
  display: flex;
  padding: 11px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;  text-decoration: none;
}
 
.menu_item_text a {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: var(--Labels-Primary---Dark, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 141.176% */
  text-decoration: none;
}

.dropdown-toggle {
  background-color: transparent;
  color: white;
  padding: 0px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.dropdown-toggle:hover {
  background-color: transparent;
  color: white;  
}

.userprofileItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 12px 0px 20px;
  align-items: center;
  align-self: stretch;
}
.userprofileFoto{
  width: 52px;
  height: 52px;
  border-radius: 100px;
  
}
.userprofileTitle{
  display: flex;
  padding: 11px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--Labels-Primary---Dark, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 141.176% */
}
.userprofileText{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
}

.item_button {
  color: var(--Labels-Primary---Dark, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 141.176% */
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.item_button:hover {
  color: var(--Labels-Primary---Dark, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 141.176% */
 
  text-decoration: none;
  background-color: transparent;
  border: none;
  
}