.uploadBack{
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background: rgba(20, 20, 20, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center; 
    z-index: 99999999999999999 ;
}
.uploadBody {
    display: flex;
    width: 320px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 32px;
    border: 1.4px solid rgba(255, 255, 255, 0.40);
    background: rgba(128, 128, 128, 0.50);
    background-blend-mode: luminosity;

    /* Blur */
    backdrop-filter: blur(50px);
}

.uploadappIcon{
    width: 52px;
    height: 52px;
}

.uploadContent {
    display: flex;
    width: 270px;
    padding: 8px 16px;
    flex-direction: column;
    align-items: center;
    gap: 2px;

}
.uploadTitle {
    align-self: stretch;
    color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 141.176% */
}

.uploadText {
    align-self: stretch;
    color: var(--Text-Secondary, rgba(255, 255, 255, 0.23));
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
}

.uploadSeperator {
    display: flex;
    padding-bottom: 4px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.uploadSeperator_Sub
{
    height: 1px;
    align-self: stretch;
    border-radius: 100px;
    background: var(--Separators-Separator, linear-gradient(0deg, rgba(94, 94, 94, 0.15) 0%, rgba(94, 94, 94, 0.15) 100%), rgba(255, 255, 255, 0.07));
    background-blend-mode: color-dodge, lighten;

}

.uploadButton {
    display: flex;
    flex-direction: row;
    width: 370px; 
    padding: 0px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
}

.uploadButtonText { 
    color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.uploadButtonappIcon {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
}

.uploadfileinput {
    color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: white; 
    display: flex;
    flex-direction: row; 
    padding: 0px 0px;
    justify-content: center;
    align-items: center;
     
}

input[type="file"]::file-selector-button {
    background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
    background-blend-mode: color-dodge, lighten;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.errorMessage {
    color: white;
}