* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100vh;  
  width: 100vw;   
  background: #000;  
  overflow: hidden;    
  font-family: Roboto;
  overflow-x: hidden;
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
 
}

#root {
  height: 100vh;  
  width: 100vw;   
  align-items: center;
}

header {
  position: -webkit-sticky;  
  position: sticky;
  margin-top: 1px;  
  display: block;
  width: 100%;
  z-index: 1000;   
  height: 80px;
  background: var(--Windows-Glass, rgba(41, 40, 40, 0.93));
  border-top-left-radius: 42px;
  border-top-right-radius: 42px;     
  border-top: 1px solid var(--windows-stroke-glass-specular, rgba(255, 255, 255, 0.40));  
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  box-shadow: 0 2px 4px rgba(103, 93, 93, 0.1);
  z-index: 1000;  
}

.defaultwindow { 
  display: flex;
  width: 100%; 
  height:  calc(100vh);  
  align-items: center;  
  justify-content: center;
  margin: 0; 
  padding: 12px; 
  padding-top: 8px;
} 

.window { 
  display: flex;   
  height:  calc(100vh - 15px);  
  width: 100%; 
  top :  0px;   
  flex-direction: column;
  justify-content: space-around;
  align-items: center; 
  align-self: stretch;
  border-bottom: 1px solid var(--windows-stroke-glass-specular, rgba(255, 255, 255, 0.40)); 
  border-left: 1px solid var(--windows-stroke-glass-specular, rgba(255, 255, 255, 0.40)); 
  border-right: 1px solid var(--windows-stroke-glass-specular, rgba(255, 255, 255, 0.40)); 
  background: var(--Windows-Glass, rgba(128, 128, 128, 0.30));
  background-blend-mode: luminosity;
  backdrop-filter: blur(50px);  
  border-radius: 42px;
  border-bottom-right-radius: 42px;
  padding-bottom: 20px;
}

.content {
  display: flex;   
  align-items: flex-start; 
}

.navigationbar_ai {
  display: flex;
  height: 100px; 
  width: 100%;
  padding-right: 20px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  flex-direction: row; 
  z-index: 1110;
  position: absolute;
  top: 0;
}

.centered {
  justify-content: center;
  display: flex;
  height: 100px; 
  width: 100%;
  padding-right: 20px; 
  text-align: center;
  align-items: center;
  flex-direction: row; 
  z-index: 1110;
  position: absolute;
  top: 0;
}

.icon_outlinemenu{
  width: 32px;
  height: 32px;
}

.menuApp {
  max-width: 55px;  
}

.logo_title { 
  align-items: center; 
  max-width: 52px;  
  top : 25px; 
}

.userphoto { 
  width:  75px; 
}

.app_icon { 
  width: 40px;
  height: 40px; 
  align-items: center;
  display: flex; 
  
  background: transparent !important;
}

.aigenerator {
  align-self: stretch;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 126.316% */
}


.icons_avatar {
  width: 32px;
  height: 32px;
  border-radius: 100px; 
}

.userprofileFoto_small{
  width: 32px;
  height: 32px;
  border-radius: 100px;
 
}

.buttons {
  display: flex;
  justify-content: center;
  flex-direction: row;
  z-index: 1000000;
  gap: 10px;
  position: absolute;
  top :  25px;   
  right: 20px;
}

.button_symbol {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
}

.symbol {
  display: flex;
  width: 44px;
  height: 44px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 115.789% */
}

.icon_coin {
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.chat_table {    
  flex-grow: 1; 
  display: flex; 
  padding: 0px 0px;
  flex-direction: column;
  align-items: flex-start; 
  align-self: stretch; 
  margin-right: 10px;
  margin-left: 10px;
  width: 98%;

}

.messages_table {    
  margin-left: 30px;
  flex-grow: 1; 
  display: flex; 
  padding: 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: scroll; 
  height:  calc(100vh - 230px);  
  width: calc(100vw - 110px)  ;
}

.messageia {
  display: flex;
  padding: 10px 10px 20px  10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

}

.ainame_rowplain{
  display: flex;
  height: 58px;
  align-items: center;
  align-content: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}

.ainame {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 129.412% */
}

.row_plain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch; 
}

.table_contents {
  display: flex;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 10px;
}

.image_table_contents {
  display: flex;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--Colors-Blue, #0A84FF);
}

.title_table_contents { 
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  flex: 1 0 0;
}

.messageuser {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
}

.messageuser_username {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 129.412% */
}

.messageuser_row_plain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -1px;
  align-self: stretch;
}

.messageuser_table_contents { 
  display: flex;
  padding-left: 8px;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 10px;
}

.messageuser_image {
  display: flex;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #FF0AC9;
}

.messageialoading {
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.messageialoading_AIName {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 129.412% */
} 

.imagegenerated_main {
  width: 100%;
  text-align: left; 
  padding: 0px;    
  display: flex;
  margin: 0; 
  align-items: flex-start;   
  
}

.imagegenerated_main_sub {
  width: 100%;
  padding: 0px; 
  text-align: left; 
  align-items: flex-start;    
}

.imagegenerated {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  align-self: stretch;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  
}

.imagegenerated_aiimage {
  width: 500px;
  height: 540px;
  filter: drop-shadow(8px 4px 29px rgba(0, 0, 0, 0.25));

}

.imagegenerated_frame {
  width: 500px;
  height: 500px; 
  border-radius: 28px;
  border: 1px solid #FFF;
  background: linear-gradient(123deg, rgba(180, 180, 180, 0.25) 1.74%, rgba(79, 79, 79, 0.25) 1.75%, rgba(255, 255, 255, 0.25) 33.05%, rgba(175, 175, 175, 0.25) 97.16%);
  backdrop-filter: blur(40px); 
  align-items: center;   
  display: flex; 
  justify-content: center;
}

.imagegenerated_imageai {
  width: 480.684px;
  height: 480.684px;
  flex-shrink: 0;
  border-radius: 19.008px;
  object-fit: cover;
  background:  
  rgba(0, 0, 0, 0.25) 0.744px -18.362px / 100% 125.934% no-repeat;
}

.imagegenerated_rectangle {
  width: 96%;
  height: 96%; 
  border-radius: 19.008px;
  background:  
  rgba(0, 0, 0, 0.25) 0.744px -18.362px / 100% 125.934% no-repeat; 
}

.imagegenerated_createpost {
  color: #C9C9C9;
  text-align: right;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 10px; 
}

.imagegenerated_createpost a {
  color: #C9C9C9;
  text-decoration: underline; 
}

.messagebox { 
  position: absolute;
  top :  calc(100vh - 110px);  
  width:  calc(100% - 30px);   
  display: flex;
  height: 62px;  
  padding:  0;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 32px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.16) 0%, rgba(94, 94, 94, 0.16) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, normal;
}

.image_messagebox_left {
  width: 36px;
  height: 36px;   
  margin-left: 25px;
  margin-bottom: 5px;
}

.image_messagebox_right {
  width: 36px;
  height: 36px;
  margin-right: 25px;
}

.messagebox_textarea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
}

.messagebox_textarea_title { 
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight:400;
  line-height: 22px; /* 129.412% */
  background:transparent;
  height: 100%;
  width: 100%;
  padding: 5px;
  margin: 0%;
  border: none;
  outline: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
} 
::-webkit-scrollbar-track {
  border-radius: 32px;height: 10px;
  background: rgba(88, 84, 84, 0.705);
}

::-webkit-scrollbar-thumb {
  border-radius: 32px;
  background: rgba(223, 221, 221, 0.644);
  max-height: 5px;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 32px;
  background: rgba(94, 94, 94, 0.18);
}
::-webkit-scrollbar-button {
  height: 10px;
}

.button_nostyle {
  width: 36px;
  height: 36px;
  background-color:  transparent;  
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.16) 0%, rgba(94, 94, 94, 0.16) 100%), rgba(255, 255, 255, 0.06);
  border-radius: 40px;
  border: none;

}

.button_nostyle :hover {
  background-color:  #C9C9C9;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.16) 0%, rgba(94, 94, 94, 0.16) 100%), rgba(255, 255, 255, 0.06);
  border-radius: 40px;
}

/* My Studio */

.MyStudioContent {  
  flex-grow: 1; 
  display: flex; 
  padding: 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: scroll; 
  height:  calc(100vh - 150px);  
  width: calc(100vw - 65px);
  z-index: 9999;
}

.navbar {
  display: flex;
  position: relative;
  flex :1;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 32px; 
  z-index: 1121;
}

.navbar_creations {
  position: relative;
  display: flex;
  flex :1;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 32px; 
  max-height: 90px;
} 

.navbar_spotlight {
  display: flex; 
  flex :1;
  padding: 24px;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  align-self: stretch;
  border-radius: 32px; 
  height: 150px;
  z-index: 1121;  

 
} 

.createpost_editselection {
  display: flex;
  padding-bottom: 20px;
  flex :1;
  padding: 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 32px;
  width: calc(100vw - 70px);
}

.statusMyCreation {  
  margin-top: -70px; 
  margin-bottom:  40px;
  width: 100%;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 19px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px; /* 126.316% */
}

.button_createpost {
  display: flex;
  height: 52px;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 500px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
  z-index: 1121;
}

.button_hide {
  display: flex;
  height: 45px;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 500px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
  z-index: 1121;
}

.button_createpost_appIcon {
  width: 40px;
  height: 40px;
}

.button_cancel_appIcon {
  width: 29px;
  height: 29px;
}

.button_delete_appIcon {
  width: 26px;
  height: 28px;
}

.button_submit_appIcon {
  width: 30px;
  height: 30px;
}

.button_back_appIcon {
  width: 35px;
  height: 35px;
}

.button_createpost_label {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.mystudioback_label {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}


.button_favorites {
  display: flex;
  height: 52px;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 500px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
} 

.button_favorites_appIcon {
  width: 29px;
  height: 29px;
}

.button_favorites_appIcon button {
  width: 29px;
  height: 29px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.button_favorites_label {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  cursor: pointer;
}

.button_myposts_creations {
  display: flex;
  height: 52px;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 500px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
}

.button_myposts_creations_appIcon {
  width: 22px;
  height: 22px;
}

.button_myposts_creations_label {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.button_next_appIcon {
  width: 35px;
  height: 35px;
}

.warning_mystudioPanel {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;  
}

.warning_mystudioPanel_Text {
  display: flex;
  width: 270px;
  padding: 8px 16px;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  color: #fff;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 117.647% */
}

.table_mystudio {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: center;
  gap : 40;
  flex: 1 0 0;
  align-self: stretch;
  padding-bottom: 20px; 
}

.table_mycreation { 
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: center; 
  flex: 1 0 0;
  align-self: stretch;
  padding-bottom: 20px; 
  z-index: 11 ;
}

.myuploads_creations {
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 46px;
  border: 1.4px solid rgba(255, 255, 255, 0.40);
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
  backdrop-filter: blur(34px);
}

.mc_titlebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: calc(100vw - 240px);
}

.mc_title {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 117.647% */
}

.myuploads_button {
  display: flex;
  height: 52px;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 500px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
}

.myuploads_button_AppIcon {
  width: 22px;
  height: 22px; 
}

.myuploads_button_label {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;  
}

.table_separator {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.separator {
  height: 1px;
  align-self: stretch;
  border-radius: 100px;
  background: var(--Separators-Separator, linear-gradient(0deg, rgba(94, 94, 94, 0.15) 0%, rgba(94, 94, 94, 0.15) 100%), rgba(255, 255, 255, 0.07));
  background-blend-mode: color-dodge, lighten;
}

.aiimages_preview {
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;   
  align-self: stretch;
  gap: 12px; 
  border-radius: 8px;  
}

.corner_rounding {
  display: flex;
  align-items: center;
  flex-wrap: wrap; 
  gap: 10px;
  align-self: stretch;
  border-radius: 8px; 
  min-height: 250px;    
  width: 100%;
  height: auto;
}

.photos_favorite {
  position: relative;
  display: flex;
  width: 250px;
  height: 250px;
  border-radius: 8px;
  background:   lightgray 50% / cover no-repeat;     
  object-fit: contain;
  align-items: center;
  text-align: center;  
  justify-content: center;
  
}
 
.photos_favorite img {  
  max-width: 250px;
  max-height: 250px;
  width: auto; 
  height: auto;
  border-radius: 8px;
  background:   lightgray 50% / cover no-repeat;      
}

.showAll {
  display: flex;
  height: 44px;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 16px; 
}

.showAll_button {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 11111;
}

.showAll_button:hover {
  color: #E6E6E6;
  background-color: transparent;
  border: none; 
}

.showAll_text {  
 color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SpaceHeight {
  height: 20px;
}

.favorite_icon {
  position: absolute; 
  bottom: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 24px;  
  color: #313030;  
}

.favorite_icon.filled {
  color: white;  
}

.favorite_icon svg {
  width: 20px;
  height: 20px;
}

.favorite_nostyle {
  width: 36px;
  height: 36px;
  background-color:  transparent;  
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.16) 0%, rgba(94, 94, 94, 0.16) 100%), rgba(255, 255, 255, 0.06);
  border-radius: 40px;
  border: none; 
}

.favorite_nostyle :hover {
  background-color:  #C9C9C9; 
  border: none;
}

.checkbox_icon {
  position: absolute; 
  bottom: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 24px;  
  color: #c1bbbb;  
}

.checkbox_icon_spotlight {
  position: absolute; 
  bottom: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 24px;  
  color: #c1bbbb;  
}

.checkbox_container {
  display: inline-block;
  vertical-align: middle;
}

.checkbox_custom {  
  position: absolute; 
  opacity: 0;
  cursor: pointer;
}

.checkbox_custom:checked + .checkbox_label:before {
  content: '\2713';  
  display: block;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border: 2px solid #FFF;  
  border-radius: 100px;  
  color: #fff;  
  background: linear-gradient(0deg, rgba(80, 78, 78, 0.888) 0%, rgba(50, 49, 49, 0.778) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), rgba(220, 208, 208, 0.514);
}

.checkbox_label {
  position: relative;
  display: inline-block;
  padding-left: 20px; 
  cursor: pointer;
  vertical-align: middle;
}

.checkbox_label:before {
  content: '';
  position: absolute;
  left:  0;
  bottom:  0px;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  border: 2px solid #e6e6e6c5(149, 146, 146, 0.895); 
  background: linear-gradient(0deg, rgba(80, 78, 78, 0.888) 0%, rgba(50, 49, 49, 0.778) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), rgba(220, 208, 208, 0.514);
  background-blend-mode: color-dodge, lighten, saturation;
  backdrop-filter: blur(20px);
}

.delete_button {
  display: flex;
  height: 52px;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 500px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
}

.delete_button_appIcon {
  width: 25.778px;
  height: 29px;
  stroke-width: 2px;
  stroke: #FF453A;
}

.delete_button_Text {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  width: 83px;
} 

/* My Studio Details*/

.mystudioDetails_right {
  display: flex; 
  padding: 8px 11px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 2px;
}

.mystudioDetails_right_saveDraft {
  display: flex;
  height: 52px;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 500px;
}

.mystudioDetails_titlebar {
  display: flex;
  padding: 0px  4px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  align-self: center; 
  flex-direction: column;
  width: 100%;
}

.mystudioDetails_title {
  align-self: center;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; 
}

.mystudioDetails_table  {
  display: flex; 
  padding:  26px 24px;
  align-items: flex-start;
  gap: 42px; 
  align-self: stretch;
  min-height: 500px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 11113;
}

.mystudioDetails_table_left {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 42px; 
  align-self: stretch;
} 

.table_left_textfield {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  width: 395px;
  height: 44px;
  padding: 0px 8px 0px 20px;
  align-items: center;
  border-radius: 12px;
  background: var(--Recessed, linear-gradient(0deg, rgba(22, 21, 21, 0.9) 0%, rgba(28, 27, 27, 0.927) 100%), rgba(15, 15, 15, 0.911));
  background-blend-mode: luminosity, color-burn;
  box-shadow: 0px -0.5px 1px 0px rgba(31, 30, 30, 0.979) inset, 0px -0.5px 1px 0px rgba(20, 20, 20, 0.815) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;
}

.textfield_text input {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
  background-color: transparent;
  width: 330px;
  height: 44px;
  height: 105px;
  border: none;
  outline: none; 
  
}

.alertmessage { 
  padding-right: 5px;
}

.textfield_clearbuttonbox {
  width: 28px;
  height: 28px; 
  border-radius: 100px;
  background: var(--Controls-Idle, linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.07));
  background-blend-mode: color-dodge, normal;
  text-align: center;
  display: flex;
  align-items: center; 
  justify-content: center;
  vertical-align: baseline;
}

.textfield_clearbutton {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.selectbuttons {
    display: flex;
    width: 440px;  
    align-items: center; 
    height: 52px;
    padding: 0px 25px;
    justify-content: space-between;  
    border-radius: 500px;
    background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
    background-blend-mode: color-dodge, lighten;
    border-color: #FFF;
    border :1px solid  #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;  
}

.selectbuttons select { 
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;  
    text-align: center; 
    padding-left: 12px;
    padding-right: 10px;
}

.category_select {
  display: flex;
  flex-direction: row;
  height: 44px;
  padding: 0px 10px 0px 10px; 
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0; 
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96)); 
}

select  {
  background: transparent; 
  border :none;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));  
}

.category_select select:hover {
  display: flex;
  flex-direction: row;
  height: 40px;
  gap: 5px; 
  border-radius: 100px; 
  background: var(--Controls-Hover, linear-gradient(0deg, rgba(94, 94, 94, 0.06) 0%, rgba(94, 94, 94, 0.06) 100%), rgba(255, 255, 255, 0.04));
  background-blend-mode: color-dodge, lighten; 
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96)); 
}

.category_select.selected select {
  display: flex;
  flex-direction: row;
  height: 40px; 
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #FFF;
  background-blend-mode: lighten;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12); 
  color: var(--Colors-Black, #000);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 164.706% */
} 

option {
  background-color: #313030; 
  width: 100%; 
  text-align: left; 
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96)); 
}

select:focus-visible {
  outline: none;  
}

.textfield_big {
  display: flex;
  width: 535px;
  height: 119px;
  padding: 10px;
  align-items: flex-start;
  border-radius: 12px;
  background: var(--Recessed, linear-gradient(0deg, rgba(22, 21, 21, 0.9) 0%, rgba(28, 27, 27, 0.927) 100%), rgba(15, 15, 15, 0.911));
  background-blend-mode: luminosity, color-burn;
  box-shadow: 0px -0.5px 1px 0px rgba(31, 30, 30, 0.979) inset, 0px -0.5px 1px 0px rgba(20, 20, 20, 0.815) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;
}

.textfield_big_text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--Text-Secondary, rgba(255, 255, 255));
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;  
  background-color: transparent;
  width: 530px;
  height: 105px;
  border: none;
  outline: none;
}

.requestBar {
  align-items: flex-start;
  width: 100%;
  margin-top: 10px; 
}

.requestText {
  margin-left: 15px;
  overflow: hidden;
  color: var(--Labels-Primary---Dark, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: italic;
  font-weight: 600;
  line-height: 25px; /* 147.059% */
  letter-spacing: -0.4px; 
}

.requestBar_big_cr {
  display: flex; 
  height: 90px;
  margin-top: 10px;
  padding: 10px;
  align-items: center;
  border-radius: 12px;
  background: var(--Recessed, linear-gradient(0deg, rgba(22, 21, 21, 0.9) 0%, rgba(28, 27, 27, 0.927) 100%), rgba(15, 15, 15, 0.911));
  background-blend-mode: luminosity, color-burn;
  box-shadow: 0px -0.5px 1px 0px rgba(31, 30, 30, 0.979) inset, 0px -0.5px 1px 0px rgba(20, 20, 20, 0.815) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;
}

.requestBar_big_textcr { 
  overflow: hidden;
  color: var(--Text-Secondary, rgba(255, 255, 255 ));
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;  
  background-color: transparent;
  width: 100%;
  height: 81px;
  border: none;
  outline: none;
}

.textfield_big_cr {
  display: flex; 
  height: 90px;
  margin-top: 10px;
  padding: 10px;
  align-items: center;
  border-radius: 12px;
  background: var(--Recessed, linear-gradient(0deg, rgba(22, 21, 21, 0.9) 0%, rgba(28, 27, 27, 0.927) 100%), rgba(15, 15, 15, 0.911));
  background-blend-mode: luminosity, color-burn;
  box-shadow: 0px -0.5px 1px 0px rgba(31, 30, 30, 0.979) inset, 0px -0.5px 1px 0px rgba(20, 20, 20, 0.815) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;
}

.textfield_big_textcr { 
  overflow: hidden;
  color: var(--Text-Secondary, rgba(255, 255, 255 ));
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;  
  background-color: transparent;
  width: 100%;
  height: 81px;
  border: none;
  outline: none;
}

.mystudioDetails_table_right {
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 46px;
  border: 1.4px solid rgba(255, 255, 255, 0.40);
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.678) 0%, rgba(67, 66, 66, 0.836) 100%), rgba(98, 96, 96, 0.06);
  background-blend-mode: color-dodge, lighten;
  backdrop-filter: blur(34px);
}

.mystudioDetails_table_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.mystudioDetails_table_header_titlebox {
  display: flex;
  width: 493px;
  padding: 9px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px; 
}

.table_header_title {
  align-self: stretch;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;  
}

.table_header_description {
  align-self: stretch;
  color: var(--Text-Secondary, rgba(255, 255, 255, 0.23));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mystudioDetails_imagesOver {
  overflow-x: hidden;
  overflow-y: scroll; 
  height: 300px;  
  max-width:  500px;  
}

.mystudioDetails_table_AI_Preview {
  display: flex;
  padding: 9px 0px 10px 0px;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  border-radius: 16px; 
  overflow-x: hidden;
  overflow-y: scroll; 
  height: 300px;  
  max-width:  550px;   
}

 

.mystudioDetails_table_corner_rounding {
  flex-wrap: wrap; 
  display: flex;
  align-items: stretch;   
  align-self: stretch;
  gap: 10px;
  border-radius: 16px;
  z-index: 2;
}

.mystudioDetails_table_corner_photobox {
  background:   lightgray 50% / cover no-repeat;     
  position: relative;
  width: 250px;
  height: 250px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  object-fit: contain;
}

.mystudioDetails_table_corner_img {
  width: 250px;
  height: 250px;
  flex-shrink: 0;
  opacity: 0.8;
  background:  lightgray 50% / cover no-repeat;
}

.mystudioDetails_table_buttonbox {
  display: flex;
  height: 44px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 6px;
  border-radius: 500px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten; 
}

.mystudioDetails_table_buttonAppIcon {
  width: 21px;
  height: 21px;
}

.mystudioDetails_table_buttonText {
  color: #FFF;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;  
}

/* My Creation */

.MyCreationContent {  
  flex-grow: 1; 
  display: flex; 
  padding: 0px 0px;
  flex-direction: column;
  align-items: flex-start; 
  justify-content: flex-start;
  align-self: flex-start;
  overflow-x: hidden;
  overflow-y: scroll; 
  height:  calc(100vh - 150px);  
  width: calc(100vw - 65px);
  z-index: 9999;
}

.mycreationFilterBox {
  display: flex; 
  align-items: center;
  gap: 12px;
  margin-right: 15px;
}

.mycreationText {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;  
}

.mycreationButton {
  display: inline-flex;
  height: 52px;
  padding: 0px 17px 0px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 160px;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 500px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
  border : 1px solid var(--Text-Primary, rgba(255, 255, 255, 0.96));
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;  
}

.detailsTable {
  display: flex;
  padding: 20px 24px;
  margin-bottom: 25px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
  border-radius: 46px;
  border: 1.4px solid rgba(255, 255, 255, 0.40);
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
  backdrop-filter: blur(34px); 
}


.detailsTableLeft {
  display: flex;
  padding: 20px 24px;
  margin-bottom: 25px;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 8px;
  border-radius: 46px;
  border: 1.4px solid rgba(255, 255, 255, 0.40);
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
  backdrop-filter: blur(34px); 

}

.mycreationTable_header { 
  display: flex;
  padding: 9px 0px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
} 
 
.mycreationTable_header1  { 
  display: flex;
  padding: 9px 0px;
  align-items: flex-start; 
  justify-content: space-between; 
  width: 98%;
} 

.mycreationTable_header1Sub { 
  display: flex;
  padding: 9px 0px;
  gap: 30px;
  align-items: flex-start;  
} 

.mycreationTable_LeftText   {  
  cursor : pointer;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;  
}  

.mycreationTable_Text  {
  flex: 1 0 0;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;  
}

.mycreationTable_appIcon {
  width: 20px;
  height: 20px;
}

.mycreationTable_boxes  {
  display: flex;
  padding: 9px 0px 10px 0px;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center; 
}

.mycreationTable_box  {
  
  width: 255px;
  height: 315px;  
}

.mycreationTable_box_rectangle  {
  width: 250px;
  height: 250px;
  flex-shrink: 0;
  border-radius: 8px;
  background: lightgray 50% / cover no-repeat;
  object-fit: contain;
}

.mycreationTable_box_1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left; 
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch; 
  padding: 3px;
}

.mycreationTable_box_rectangle_appIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.mycreationTable_box_rectangle_Text {
  display: flex; 
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  color: #FFF;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  word-wrap: break-word;  
  white-space: normal;    
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}

.mycreationTable_box_rectangle_TextLike {
  display: flex; 
  flex-direction: column;
  justify-content: center;
  width: 92%;
  flex-shrink: 0;
  color: #FFF;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  word-wrap: break-word;  
  white-space: normal;    
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}

.mycreationTable_box_rectangle_Text a { 
  flex-shrink: 0;
  color: #FFF;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
  word-wrap: break-word; 
  white-space: normal;   
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}

.mycreationTable_box_rectangle_Status{
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  color: #C9C9C9;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mycreationTable_box_rectangle_Status a {
  cursor: pointer;
}


.invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Admin */


.navbar_Admin {
  display: flex;
  position: relative;
  flex :1;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 32px; 
  height: 150px;
  z-index: 1121;
}

.MyCreationListContent {  
 
  display: flex; 
  padding: 0px 0px; 
  flex-direction: column;
  align-items: flex-start; 
  justify-content: flex-start;
  align-self: flex-start;
  overflow-x: hidden;
  overflow-y: hidden; 
  height:   calc(100vh - 150px);   
  width: calc(100vw - 65px);
  z-index: 9999;
}

.tableCreationList {
  display: flex; 
  flex-direction: column; 
  justify-content: flex-start;
  align-items: center; 
  align-self: stretch; 
  min-height: calc(100vh - 250px);  
  overflow-x: hidden;
  overflow-y: scroll;
}

.tableCreationPostBar {
  height: 40px;
  display: flex; 
  padding: 10px 24px; 
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  justify-content: space-between;  
  color: var(--Labels-Primary---Dark, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 147.059% */
  letter-spacing: -0.4px; 
}
 
.navbar_right {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; 
  justify-content: space-between;
  align-items: flex-end;   
  align-items: center;
  gap: 15px;
}

.navbar_rightSpotlight {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; 
  justify-content: space-between;
  align-items: flex-end;   
  align-items: center;
  gap: 15px;
}

.searchBox_SpotlightBar {
 
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}


.searchBox_Spotlight {
  display: flex;
  width: 305px;
  height: 44px;
  padding: 0px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background-color: #000;
  background-blend-mode: luminosity, color-burn;
  box-shadow: 0px -0.5px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px -0.5px 1px 0px rgba(255, 255, 255, 0.25) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;
}


.searchBox_Creation {
  display: flex;
  width: 305px;
  height: 44px;
  padding: 0px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background-color: #000;
  background-blend-mode: luminosity, color-burn;
  box-shadow: 0px -0.5px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px -0.5px 1px 0px rgba(255, 255, 255, 0.25) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;
}

.searchIcon_box {
  display: flex;
  width: 28px;
  height: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.searchIcon_App {
  width: 21px;
  height: 21px;
  flex-shrink: 0;
}

.search_text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--Text-Secondary, rgba(255, 255, 255, 0.23));
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;   
  background-color: #1B1A1A;
}

 
.tableCreationRow {
  display: flex; 
  padding:  0px 24px;  
  width: 100%;
  justify-content: space-between;   
  padding-bottom: 5px;
}

.tableColumn {
  display: flex;   
  width: 17%; 
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0; 
}

.tableColumnTitle {
  display: flex;   
  width: 47%;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0; 
}


.tableColumnLeft {
  display: flex;   
  width: 52%;
  flex-direction: row;
  align-items: flex-start; 
}

.tableColumnRight {
  display: flex;   
  width: 48%;
 
  flex-direction: row;  
  justify-content: flex-start;  
}

.tableColumnCenter {
  display: flex;   
  width: 50%;
  flex-direction: column;
  align-items: center;  
}

.tableColumnCheck {
  display: flex;    
  width: 47%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  gap : 10px; 
}

.tableColumnSub {
  display: flex;
  height: 56px;
  padding: 0px 19px 0px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.tableColumnBackBlack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 35px; 
  border-radius: 12px;
  background-color: #000;
  background-blend-mode: luminosity, color-burn;
  box-shadow: 0px -0.5px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px -0.5px 1px 0px rgba(255, 255, 255, 0.25) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;
  align-items: center; 
  text-align: center;
  align-self: center;
}

.tableColumnBackBlack_text {   
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500; 
  align-items: center; 
  text-align: center; 
  white-space: normal; 
  word-break: break-word;  
  overflow-wrap: break-word;
}

.tableColumnText {
  overflow: hidden;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.4px;
  flex-wrap: wrap;
  white-space: normal;  
  word-break: break-word;  
  overflow-wrap: break-word;
}

.tableColumnText a {
  cursor: pointer;
}

.checkbox_icon_adm {    
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 24px;  
  color: #c1bbbb;  
}

.checkbox_container_adm {
  display: inline-block; 
} 

.checkbox_custom_adm {       
  position: absolute; 
  opacity: 0;
  cursor: pointer;
}

.checkbox_custom_adm:checked + .checkbox_label_adm:before {
  content: '\2713';  
  display: block;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border: 2px solid #FFF;  
  border-radius: 100px;  
  color: #fff;  
  background: linear-gradient(0deg, rgba(87, 87, 87, 0.04) 0%, rgba(87, 87, 87, 0.04) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), rgba(220, 208, 208, 0.514);
}

.checkbox_label_adm {
  position: relative;
  display: inline-block;
  padding-left: 20px; 
  cursor: pointer;
  vertical-align: middle;
}

.checkbox_label_adm:before {
  content: '';
  position: absolute;
  left:  0;
  bottom:  0px;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  border: 2px solid #e6e6e6c5(149, 146, 146, 0.895); 
  background: linear-gradient(0deg, rgba(87, 87, 87, 0.04) 0%, rgba(87, 87, 87, 0.04) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), rgba(220, 208, 208, 0.514);
  background-blend-mode: color-dodge, lighten, saturation;
  backdrop-filter: blur(20px);
}

.tableCreationPageBar {
  display: flex; 
  height: 50px;
  padding: 8px 24px 16px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  justify-content: center;  
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 158.333% */
  letter-spacing: -0.4px; 
  z-index: 30000;
}

.tableCreationPageControl {
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0; 
}

.submissionText { 
  margin-left: 20px;
  color: var(--Labels-Primary---Dark, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;  
  letter-spacing: -0.4px;
}

.commentary_Row {
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px; 
}

.commentary_Column {
  display: flex;
  width: 631px;
  height: 378px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.commentary_Header {
  display: flex;
  width: 280px;
  height: 42px;
  padding: 12px 24px 0px 24px;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  color: var(--Labels-Primary---Dark, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: italic;
  font-weight: 600;
  line-height: 25px; /* 147.059% */
  letter-spacing: -0.4px;
}

.commentary_Input {
  display: flex;
  height: 312px;
  padding: 8px 20px;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 12px;
  background-color: #000;
  background-blend-mode: luminosity, color-burn;
  box-shadow: 0px -0.5px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px -0.5px 1px 0px rgba(255, 255, 255, 0.25) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;
}

.commentary_Input textarea { 
  overflow: hidden;
  color: var(--Text-Secondary, rgba(255, 255, 255 ));
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;  
  background-color: transparent;
  width: 100%;
  height: 300px;
  border: none;
  outline: none;
}

.CreationDetails_table_AI_Preview {
  display: flex;
  padding: 9px 0px 10px 0px;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  border-radius: 16px; 
  overflow-x: hidden;
  overflow-y: scroll; 
  height: 300px;  
  width:  100%;   
}

.button_update_appIcon {
  width: 21px;
  height: 21px;
}

.tableCreationDetailsBar {
  display: flex;
  width: calc(100vw - 100px);
  padding: 0px 24px;
  margin-left: 20px;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--Labels-Primary---Dark, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 147.059% */
  letter-spacing: -0.4px; 
}

.tableCreationDetailsColumn_1 {
  display: flex;
  width: 143px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0; 
  gap:10px;
}

.tableCreationDetailsColumn_2 {
  display: flex;
  width: 116px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  gap:10px;
}

.tableCreationDetailsColumn_3 {
  display: flex;
  width: 180px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  gap:10px;
}

.tableCreationDetailsColumn_4 {
  display: flex;
  width: 267px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  gap:10px;
}

.tableCreationDetailsColumn_5 {
  display: flex;
  width: 200px; 
  align-items: center;
  flex-direction: column; 
  gap:10px;
}

.columns_Header {
  overflow: hidden;
  color: var(--Labels-Primary---Dark, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 147.059% */
  letter-spacing: -0.4px;
}

.columns_Text {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.4px;
}

.column_TextBlack { 
  display: flex;
  width: 200px;
  height: 30px;
  padding: 0px 20px;
  align-items: center;
  border-radius: 12px;
  background-color: #000;
  background-blend-mode: luminosity, color-burn;
  box-shadow: 0px -0.5px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px -0.5px 1px 0px rgba(255, 255, 255, 0.25) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset; 
} 

.column_TextBlack input {
  overflow: hidden;
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  background-color: transparent;
  width: 200px;
  height: 30px; 
  border: none;
  outline: none; 
}

.creationImages {
  display: flex;
  padding: 20px 24px;
  width: calc(100vw - 120px);
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 46px;
  border: 1.4px solid rgba(255, 255, 255, 0.40);
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.678) 0%, rgba(67, 66, 66, 0.836) 100%), rgba(98, 96, 96, 0.06);
  background-blend-mode: color-dodge, lighten;
  backdrop-filter: blur(34px);
}

.creationImages_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.creationImages_header_titlebox {
  display: flex;
  width: calc(100vw - 120px);
  padding: 9px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px; 
}

.action_select {
  width: 100%; 
}

.action_select option{ 
  margin-left: 10px;
  padding-right: 0px;
}

.Listin {
  display: flex; 
  padding: 0px 15px;
  justify-content: flex-start;
  align-items: flex-start; 
  border-radius: 500px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.678) 0%, rgba(67, 66, 66, 0.836) 100%), rgba(98, 96, 96, 0.06);
  background-blend-mode: color-dodge, lighten;
  width: 160px;  
}

.Listin select {  
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;  
  padding: 7px; 
  width: 130px;
}

.button_archive {
  display: flex; 
  padding: 0px 15px;
  justify-content: flex-start;
  align-items: flex-start; 
  border-radius: 500px;
  background: white;
  background-blend-mode: color-dodge, lighten;
  width: 160px;
  color : #000;
}

.button_archive select { 
  padding: 7px; 
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;   
  color : #000;
  width: 130px;
}

.button_archive_appIcon {
  width: 21px;
  height: 21px;
}

/* Spotlight */

  
.defaultwindowSpotLight { 
  display: flex;
  width: calc(100vw);   
  height:  calc(100vh);  
  align-items: center;  
  justify-content: center;
  margin: 0;  
} 

.windowSpotLight { 
  display: flex;   
  height:  calc(100vh - 20px);  
  width: calc(100vw);  
  top :  0px;   
  flex-direction: column;
  justify-content: space-around;
  align-items: center; 
  align-self: stretch;  
  padding-bottom: 10px;
  padding-top: 10px;
}

.contentSpotLight {
  display: flex;
  width: calc(100vw - 20px);  
  height: calc(100vh - 20px); 
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
}

.sidebar_Left {
  display: flex; 
  display: -webkit-box; /* Safari için */
  display: -ms-flexbox; /* Eski IE için */
  height: calc(100vh - 18px);
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: 1px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 30px 0px 0px 30px;
  border: 1px solid #FFF;
  background: linear-gradient(123deg, rgba(180, 180, 180, 0.25) 1.74%, rgba(79, 79, 79, 0.25) 1.75%, rgba(255, 255, 255, 0.25) 33.05%, rgba(175, 175, 175, 0.25) 97.16%);
  backdrop-filter: blur(40px);
}
 

.sidebar_LeftSub {
  padding-top: 100px;
  display: flex;
  padding-left: 25px;
  gap: 20px;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  width: 320px;
  height: calc(100vh - 20px);
  flex-shrink: 0;
  border-radius: 30px 0px 0px 30px;
  background: linear-gradient(123deg, rgba(0, 0, 0, 0.19) 1.74%, rgba(79, 79, 79, 0.19) 1.75%, rgba(0, 0, 0, 0.19) 33.05%, rgba(0, 0, 0, 0.19) 97.16%);
  backdrop-filter: blur(40px); 
}
 
.sidebar_Right { 
  height: calc(100vh - 18px);
  display: flex; 
  display: -webkit-box; /* Safari için */
  display: -ms-flexbox; /* Eski IE için */
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: 1px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 0px 30px 30px 0px;
  border: 1px solid #FFF;
  background: linear-gradient(123deg, rgba(98, 94, 94, 0.25) 1.74%, rgba(54, 53, 53, 0.586) 1.75%, rgba(137, 133, 133, 0.745) 33.05%, rgba(175, 175, 175, 0.25) 97.16%);
  backdrop-filter: blur(40px);
}
 
.sidebar_RightSub {
  display: flex;
  flex :1;
  width: 320px;
  height: calc(100vh - 20px);
  padding-top: 100px;
  padding-left: 25px;
  align-items: flex-start;
  justify-content: flex-start;
  gap : 25px;
  align-self: stretch;
  flex-direction: column; 
  flex-shrink: 0; 
  border-radius: 0px 30px 30px 0px; 
  background: linear-gradient(123deg, rgba(98, 94, 94, 0.25) 1.74%, rgba(54, 53, 53, 0.586) 1.75%, rgba(137, 133, 133, 0.745) 33.05%, rgba(175, 175, 175, 0.25) 97.16%);
  backdrop-filter: blur(40px);
}

@-moz-document url-prefix() {
  .sidebar_Left {
    height: calc(100% );
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .sidebar_Right {
    height: calc(100% );
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .contentSpotLight {
    display: flex;
    width: calc(100vw - 20px);  
    height: calc(100vh - 16px); 
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
  }
   
}


.middleBlock {
  width: 100%;
  height: calc(100vh - 10px); 
  flex:1;
  display: flex;
  align-items: center; 
  flex-direction: column; 
  justify-content: space-evenly; 
  padding-top: 10px;
  gap : 7px;
}

.sliderBox { 
  margin-top: 100px;
  margin-bottom: 30px;
  flex:1;
  display: flex;  
  align-items: center;
  align-self: center;
  justify-content: center; 
  z-index: 33;    
}

.nodata {
  height: 25%; 
}

.rectangleFull {
  width: 400px;
  height: 400px; 
  filter: drop-shadow(8px 4px 29px rgba(0, 0, 0, 0.25)); 
  align-items: center; 
  flex-direction: column;
}

.rectangleFrame {
  width: 400px;
  height: 400px; 
  border-radius: 20px;
  border: 1px solid #FFF;
  background: linear-gradient(123deg, rgba(180, 180, 180, 0.25) 1.74%, rgba(79, 79, 79, 0.25) 1.75%, rgba(255, 255, 255, 0.25) 33.05%, rgba(175, 175, 175, 0.25) 97.16%);
  backdrop-filter: blur(40px);
  padding: 7px;  
}



.imagesPages {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
}

.frameAction {
  display: flex;
  width: 185px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #FFF;
  background: linear-gradient(123deg, rgba(79, 79, 79, 0.10) 1.74%, rgba(79, 79, 79, 0.10) 1.75%, rgba(255, 255, 255, 0.10) 33.05%, rgba(79, 79, 79, 0.10) 97.16%);
  backdrop-filter: blur(40px);
}

.iconBack {
  display: flex;
  width: 21.992px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  color: rgba(255, 255, 255, 0.70);
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.iconBack_App{
  width: 19.2px;
  height: 16.8px;
  fill: rgba(217, 217, 217, 0.60);
}

.iconVote {
  display: flex;
  width: 33px;
  height: 45px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  color: rgba(255, 255, 255, 0.70);
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.iconVote_App {
  width: 24px;
  height: 20.485px;
  flex-shrink: 0;
  fill: rgba(254, 246, 246, 0.50);
  stroke-width: 1px;
  stroke: #FFF;
}
  
.iconNext {
  display: flex;
  width: 24px;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0; 
  color: rgba(255, 255, 255, 0.70);
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
} 

.iconNext_App {
  width: 19.2px;
  height: 16.8px;
  fill: rgba(217, 217, 217, 0.60);
}

.wishlistAction {
  display: inline-flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px; 
  color: rgba(255, 255, 255, 0.70);
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

 
.showAll_button:hover .wishlistAction svg {
  fill: #ffffff;
  fill-opacity: 1;
  stroke: #ffffff;  
  stroke-width: 2px;  
}

.wishlistAction_App {
  width: 14px;
  height: 16px;
  stroke-width: 1px;
  stroke: rgba(217, 217, 217, 0.60);
}

.bottomRectangle { 
    z-index: 333;
    align-items: center;
    align-self: center;
    display: flex;
    flex :1;
    flex-direction: column;
    justify-content: center; 
}

.middleBlockSVG  {    
  align-items: center;
  align-self: center;
  z-index: 1;
}

.middleBlockSVG  svg {
  position: absolute; 
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 50%;

} 

.bottomBlock {
  display: flex;
  width: 100%;
  padding: 0px 10px; 
  justify-content: space-between;
  align-items: center;   
}

.seeAllCreation { 
  width: 200px;
  height: 40px; 
  text-align: center;
  display: flex;
  gap : 25px;
  padding: 8px; 
  align-items: center;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.30);
  color: #FFF; 
  font-size: 15px;
  font-style: normal;
  font-weight: 500; 
}

.progressBar {  
  position: relative;
  width: 200px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  background: rgba(53, 51, 51, 0.853);  
  background-color: #0b0b0b; 
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.progressBarSub {
  width: 200px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  background: rgba(53, 51, 51, 0.853);  
  background-color: #0b0b0b; 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progressText {
  display: flex;
  width: 35px;
  height: 12px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #FFF;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.progressLine {
  height: 4px;  
  border-radius: 0px 0px 400px 400px;
  background: var(--Views-Thin, linear-gradient(0deg, rgba(94, 94, 94, 0.13) 0%, rgba(94, 94, 94, 0.13) 100%), rgba(255, 255, 255, 0.07));
  background-blend-mode: color-dodge, lighten;
}

.imagesPageBar {
  display: flex; 
  height:  50px;
  padding:  8px  4px 1px 14px; 
  align-items: flex-start;
  position: relative;
  gap: 16px;
  justify-content: center;  
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 158.333% */
  letter-spacing: -0.4px;  
}

.imagePageControl {
  display: flex; 
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -32px; 
  gap: 10px; 
  width: 100%;
}

.creatorImage {
  display: flex;
  width: 128px;
  height: 128px;
  flex-shrink: 0;
  border-radius: 60px;
   
  object-fit: contain;
  align-items: center;
  text-align: center;  
  justify-content: center;
  z-index: 99;
}

.creatorImage img {
  max-width: 128px;
  max-height: 128px;
  border-radius: 60px;
  width: auto;
  height: auto;  
  z-index: 9;
}

.creatorTextTop {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 50px;
  max-height: 50px;
}

.creatorTextTop_Big {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 50px;
  max-height: 70px;
}

.creatorName {
  color: #FFF;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
 
}

.creatorName a {
  color: #FFF;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
}

.creatoraboutLink {
  color: rgba(174, 174, 174, 0.68);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.creatoraboutLink a {
  color: rgba(174, 174, 174, 0.68);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.creatorTotalCreation {
  width: 177px;
  height: 25px;
  max-height: 25px;
  flex-shrink: 0;
  display: flex;
  flex: 1;
  flex-direction: row;
  gap : 5px;
  align-items: center;
}

.creatorTotalCreationText {
  color: rgba(255, 255, 255, 0.95); 
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
  
.creatorAbout {
  color: rgba(255, 255, 255, 0.95); 
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-right: 20px;
  margin-bottom: 20px;
  max-height: calc(100vh - 200px);
  overflow: hidden;
  text-overflow: ellipsis; 
  cursor: pointer;
  text-align: justify; 
}

.creatorAbout:hover {
  overflow-y: auto;
  white-space: normal;
  padding-right: 20px;  
}

.creationThumb {
  max-width: 125px; 
  max-height: 125px;
  opacity: 0.8;
  background:   lightgray 50% / cover no-repeat;
  border-radius: 19.008px;
  margin-left: 20px;
  object-fit: contain;
}

.creationThumb img {
  max-width: 125px;
  max-height: 125px;
  width: auto; 
  height: auto;
  border-radius: 19.008px;
}

.categoryFrame {
  display: inline-flex; 
  align-items: flex-start;
  gap: 16px;
  height: 50px;
  max-height: 50px;
}

.categorySub {
  color: rgba(255, 255, 255, 0.50); 
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.spotlightContainer {
  display: flex;    
  width: 100%;   
  flex-direction: row; 
  align-items: center; 
  gap : 5px;
  padding-right: 20px;
  max-height: 150px;
}

.spotlightItem img {
  width: 45px;   
  height: 45px; 
}

.spotlightImage {
  cursor: pointer;
}

 
.showAll_button:hover .iconVote svg {
  fill: #ffffff;
  fill-opacity: 1;
  stroke: #FEF6F6;  
  stroke-width: 2px;  
}
 
.hidden {
  display: none;
}

.lastRecordMessageBlock { 
  width: 300px;    
  flex:1;
  display: flex;
  align-items: center; 
  flex-direction: column;   
  gap: 5px; 
}

.lastRecordMessage { 
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal; 
}

.lastRecordMessage_App { 
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.lastRecordActionBlock {
  display: flex;
  width: 250px;
  padding: 8px 14px;
  justify-content: space-between;
  align-items: center;
  width: 241px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #FFF;
  background: linear-gradient(123deg, rgba(79, 79, 79, 0.10) 1.74%, rgba(79, 79, 79, 0.10) 1.75%, rgba(255, 255, 255, 0.10) 33.05%, rgba(79, 79, 79, 0.10) 97.16%);
  backdrop-filter: blur(40px);
}

.iconSuffle {
  display: flex;
  width: 33px;
  height: 45px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  color: rgba(255, 255, 255, 0.70);
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.spotlightContent {  
  flex-grow: 1; 
  display: flex; 
  padding: 0px 0px;
  flex-direction: column;
  align-items: center; 
  justify-content: flex-start;
  align-self: center;
  overflow-x: hidden;
  overflow-y: scroll; 
  height:  calc(100vh - 150px);  
  width: calc(100vw - 65px);
  z-index: 9999;
}

.selectFilterSpotlight {
  display: flex;
  width: 500px;  
  align-items: center; 
  height: 52px; 
  justify-content: space-between;  
  border-radius: 500px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
  border-color: #FFF;
  border :1px solid  #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;  
}

.selectFilterSpotlight select { 
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;  
  text-align: center; 
  padding-left: 12px;
  padding-right: 10px;
}

 
body, .mycreationTable_boxes {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none; 
  scrollbar-width: none;   
}

 
body::-webkit-scrollbar, .mycreationTable_boxes::-webkit-scrollbar {
  display: none;
}

.infinite-scroll-container {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
}

 
.infinite-scroll-container::-webkit-scrollbar {
  display: none;
}

.spotlightback_label {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}


/* user profil */

.profilePhotoBar {
  display: flex;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.profilePhotoSub {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  text-align: center ;
  gap: 8px;
}

.profilePhoto {
  display: flex;
  width: 128px;
  height: 128px;
  padding: 94px 0px 0px 94px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 100px;
  background:  lightgray 50% / cover no-repeat;
}

.profileTitleLink {
  color: var(--Text-Secondary, rgba(255, 255, 255, 0.23));
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  align-self: stretch; 
}

.profileTitleLink input[type="file"] {
  display: none;
}

.custom-file-upload {
  background: transparent;
  color: var(--Text-Secondary, rgba(255, 255, 255, 0.23));
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  align-self: stretch;
  border: none;
  cursor: pointer;
  display: inline-block;
}

.profileTitleText {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 126.316% */
  align-self: stretch; 
}

.profileHeader {
  display: flex;
  padding: 35px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 126.316% */
}

.profileInformationTable {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  flex-wrap: wrap;
}

.profileInformationTableLeft {
  display: flex;
  padding: 0px 24px 56px 24px;
  flex-direction: column;
  align-items: center;
  gap: 42px;
  flex: 1 0 0;
  align-self: stretch;
}

.profileInformationTableTitle {
  color: white;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 117.647% */
  padding-bottom: 7px;
}

.profileInformationTableLeftTextField {
  display: flex;
  width: 395px;
  height: 44px;
  padding: 0px 8px 0px 20px;
  align-items: center;
  border-radius: 12px;
  background: var(--Recessed, linear-gradient(0deg, rgba(22, 21, 21, 0.9) 0%, rgba(28, 27, 27, 0.927) 100%), rgba(15, 15, 15, 0.911));
  background-blend-mode: luminosity, color-burn;
  box-shadow: 0px -0.5px 1px 0px rgba(31, 30, 30, 0.979) inset, 0px -0.5px 1px 0px rgba(20, 20, 20, 0.815) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;
}

.profileInformationTableRight {
  display: flex;
  width: 688px;  
  padding: 8px 16px;
  flex-direction: column;
  align-items: center;
  text-align: center; 
  gap: 8px;
}

.profileInformationTableRightTextField {
  display: flex;
  width: 650px;
  height: 210px;
  padding: 16px 20px;
  align-items: center;
  border-radius: 12px; 
  background: var(--Recessed, linear-gradient(0deg, rgba(22, 21, 21, 0.9) 0%, rgba(28, 27, 27, 0.927) 100%), rgba(15, 15, 15, 0.911));
  background-blend-mode: luminosity, color-burn;
  box-shadow: 0px -0.5px 1px 0px rgba(31, 30, 30, 0.979) inset, 0px -0.5px 1px 0px rgba(20, 20, 20, 0.815) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;
}
 

.textfield_big_text_profile {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--Text-Secondary, rgba(255, 255, 255));
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;  
  background-color: transparent;
  width: 530px;
  height: 200px;
  border: none;
  outline: none;
}
.profiletextfield_text   {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96)); 
  background: var(--Recessed, linear-gradient(0deg, rgba(22, 21, 21, 0.9) 0%, rgba(28, 27, 27, 0.927) 100%), rgba(15, 15, 15, 0.911));
  background-blend-mode: luminosity, color-burn;
  
}
.profiletextfield_text input {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;  
  width: 330px;
  height: 44px; 
  border: none;
  outline: none; 
  text-align: center;
  background: var(--Recessed, 
  linear-gradient(0deg, 
                  rgba(22, 21, 21, 0.9) 0%, 
                  rgba(28, 27, 27, 0.927) 100%), 
  rgba(15, 15, 15, 0.911)) !important; /* Degrade arka plan */
  background-blend-mode: luminosity, color-burn !important; /* Karışım modu */
 
}

/* Login */

.headerTextBlock {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.headerTitle {
  display: flex;
  width: 336px;
  padding: 8px 16px;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 126.316% */
}

.headerText {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
}

.loginTable {
  display: flex; 
  flex-direction: column;
  align-items: center;
  gap: 11px;
  flex: 1 0 0;
  align-self: stretch;
}

.signInButton {
  display: flex;
  height: 52px;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 500px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */

}
.createAccount {
  display: flex;
  height: 52px;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 500px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  background-blend-mode: color-dodge, lighten;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.loginButtonBlock {
  display: flex;
  width: 270px; 
  padding: 8px 11px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.loginButtonBlockText {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 200% */
}

.forgotPassText {
  margin-top: 10px;
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 200% */
  cursor: pointer;
}

.signInAlert {
  color: red;
  text-align: center;
}

.logoutBlock {
  font-size: 13px; 
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;font-size: 13px; 
}

input:-webkit-autofill {
  color: white;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;    
  height: 30px;
  border: none;
  outline: none; 
  background-color: #121111 ;
  background-blend-mode: luminosity, color-burn;
  -webkit-box-shadow: 0 0 0 1000px #1B1A1A inset !important;  
  -webkit-text-fill-color: white !important; 
}

 
.logintextBlock {
  display: flex;
  width: 395px;
  height: 44px;
  padding: 0px 8px 0px 20px;
  align-items: center;
  border-radius: 12px;
  background-color: #121111 ;
  background-blend-mode: luminosity, color-burn;
  box-shadow: 0px -0.5px 1px 0px rgba(31, 30, 30, 0.979) inset, 0px -0.5px 1px 0px rgba(20, 20, 20, 0.815) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;
}

.logintext   {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96)); 
  background-color: #121111 ;
  
}
.logintext input {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;  
  width: 330px;
  height: 44px; 
  border: none;
  outline: none; 
  text-align: center;
  background-color: #121111 ;
  -webkit-box-shadow: 0 0 0 1000px  #121111 inset !important; /* Şeffaf kutu gölgesi */
  -webkit-text-fill-color: white !important;
}

.tableColumnBackBlack_text a { 
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.showAll_button.disabled {
  cursor: not-allowed;
}

.search_text input {
  color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;  
  width: 250px;
  height: 30px; 
  border: none;
  outline: none;  
  background-color: #000;
 
  box-shadow: 0px -0.5px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px -0.5px 1px 0px rgba(255, 255, 255, 0.25) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;
  -webkit-box-shadow: 0 0 0 1000px  #000 inset !important; /* Şeffaf kutu gölgesi */
  -webkit-text-fill-color: white !important;
}


 
.searchWrapper {
  border-radius: 500px !important;
  background-color: #535454; 
  border : 1px solid linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
  color: #fff;
  padding-top: 5px !important;
}

.search-wrapper { 
  border-radius: 500px !important;
  background-color: #535454; 
  border-radius: 500px;
  padding: 10px; 
  color: #fff;  
  padding-top: 5px !important;
}

.multiSelectContainer input {
  background: transparent;
  border: none;
  margin-top: 6px !important;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3b82f6; /* Customize the color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}



@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@supports (-webkit-backdrop-filter: blur(0)) {
  .detailsTableLeft {
    background: linear-gradient(0deg, rgba(94, 94, 94, 0.25) 0%, rgba(94, 94, 94, 0.25) 100%), rgba(255, 255, 255, 0.1);
    background-blend-mode: overlay;
    -webkit-backdrop-filter: blur(34px);
    backdrop-filter: blur(34px);
  }
}

@supports (-webkit-touch-callout: none)  {
  .sidebar_Left {
    height: 100% !important;
    margin-top: 14px!important;
    margin-bottom: 0px;
    border-radius: 30px 0px 0px 30px;
    border: 1px solid #FFF;
    background: linear-gradient(123deg, rgba(180, 180, 180, 0.25) 1.74%, rgba(79, 79, 79, 0.25) 1.75%, rgba(255, 255, 255, 0.25) 33.05%, rgba(175, 175, 175, 0.25) 97.16%);
    backdrop-filter: blur(40px);
  }
 
  .sidebar_Right {
    height: calc(100%);
    margin-top: 14px;
    margin-bottom: 0px;
    border-radius: 0px 30px 30px 0px;
    border: 1px solid #FFF;
    background: linear-gradient(123deg, rgba(98, 94, 94, 0.25) 1.74%, rgba(54, 53, 53, 0.586) 1.75%, rgba(137, 133, 133, 0.745) 33.05%, rgba(175, 175, 175, 0.25) 97.16%);
    backdrop-filter: blur(40px);
 
  }

  .contentSpotLight {
    display: flex;
    width: calc(100vw - 20px);  
    height: calc(100vh - 16px); 
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
  }
   
}
 