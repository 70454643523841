  .slider-container {  
      width: 100%;  
      height: 100%;   
      display: flex;
      align-items: center;
      justify-content: center; 
      position: relative;
      z-index: 333;
  }
  
  .slider-wrapper {    
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;  
    width: 100%;
    perspective: 800px; 
    max-width: 600px;    
  }

  .imageSlider {
    max-width: 600px;       
    display: flex;
    flex-direction: column;  
    justify-content: space-between;   
    flex-wrap: wrap;
    height: 430px; 
  }
  
  .slider-image-wrapper {      
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out; 
    opacity: 0;  
    align-items: center; 
    text-align: center;
    display: flex;
    flex-direction: column;  
    position: absolute;
    border-radius: 20px;
    border: 1px solid #FFF;
    height: 600px;  
    width:  530px;   
    background: linear-gradient(123deg, rgba(180, 180, 180, 0.25) 1.74%, rgba(79, 79, 79, 0.25) 1.75%, rgba(255, 255, 255, 0.25) 33.05%, rgba(175, 175, 175, 0.25) 97.16%);
    backdrop-filter: blur(40px); 
    will-change: transform; 
  }

  .rectangleImages {
    align-items: center;
    max-width: 100%;
    max-height: 100%; 
    border-radius: 19.008px;  
    flex-direction: column;
    align-items: center; 
    display: flex;
    justify-content: center;
    object-fit: contain;
  }
  
  .rectangleImages img { 
    max-width: 100%; 
    max-height: 100%;  
    border-radius: 20px; 
    align-items: center;   
    object-fit: contain;
  }
 
  .slider-image-wrapper.active {
    transform: translateX(0) scale(1);
    opacity: 1;
    z-index: 2;
  }

  /* Previous image */
  .slider-image-wrapper.prev {
    transform: translateX(-45%) scale(0.7) rotateY(45deg);
    opacity: 0.6;
    z-index: 1;
  }

  /* Next image */
  .slider-image-wrapper.next {
    transform: translateX(45%) scale(0.7) rotateY(-45deg);
    opacity: 0.6;
    z-index: 1;
  }

  /* Slider buttons */
  .slider-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: #d2d0d0;
    z-index: 3;
  }
  
  @media   (min-height: 600px) and (max-height: 800px) {
    
    .sliderBox { 
      margin-top: 10px; 
    }

    .slider-container {  
      width: 380px;  
      height: 380px;     
    }
    
    .slider-wrapper {
      width: 380px;
      height: 380px;
    }

    .slider-image-wrapper {
      width: 380px;
      height: 380px;
    }
  
     
    .rectangleImages {
      width: 380px;
      height: 380px;
    } 
    
}

 

@media  (min-width: 400px) and (max-width: 600px)  {
    
  .sliderBox { 
    margin-top: 10px; 
  }

  .slider-container {  
    width: 330px;  
    height: 330px;     
  }
  
  .slider-wrapper {
    width: 330px;
    height: 330px;
  }

  .slider-image-wrapper {
    width: 300px;
    height: 300px;
  }

 
  .rectangleImages {
    width: 330px;
    height: 330px;
  }

  .rectangleImages img{
    max-width: 100%; 
    max-height: 100%;
  }
  .middleBlockSVG {
    display: none;
  }

}
  
@media   (min-height: 801px)  {
    
  .sliderBox { 
    margin-top: 10px; 
  }

  .slider-container {  
    width: 500px;  
    height: 500px;     
  }
  
  .slider-wrapper {
    width: 450px;
    height: 450px;
  }

  .slider-image-wrapper {
    width: 450px;
    height: 450px;
  }

   
  .rectangleImages {
    width: 450px;
    height: 450px;
  }

  .rectangleImages img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
 
}

@media (min-width : 601px) and (max-width: 1000px) {
  .sliderBox { 
    margin-top: 10px; 
  }

  .slider-container {  
    width: 380px;  
    height: 380px;     
  }

  .slider-wrapper {
    width: 380px;
    height: 380px;
  }

  .slider-image-wrapper {
    width: 380px;
    height: 380px;
  }
 
  .rectangleImages {
    width: 380px;
    height: 380px;
  }  

  .middleBlockSVG {
    display: none;
  }
}
 
@media (min-width : 1001px) and (max-width: 1250px) {
  .sliderBox { 
    margin-top: 10px; 
  }

  .slider-container {  
    width: 380px;  
    height: 380px;     
  }

  .slider-wrapper {
    width: 380px;
    height: 380px;
  }

  .slider-image-wrapper {
    width: 380px;
    height: 380px;
  }

  
  .rectangleImages {
    width: 380px;
    height: 380px;
  }  

  .middleBlockSVG {
    display: none;
  }
}