
@media (min-width: 350px) and (max-width: 632px) {
    html, body {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        overflow: hidden; /* Ana scroll'u kaldırır */
    }
    
    .defaultwindow { 
        height: calc(100vh - 107px);  
        align-items: flex-start;
        align-items: flex-start;  
        justify-content: flex-start;
        z-index: 99;
        
    }
    .content_menu {
        top: 34px;
        height: calc(100vh - 107px);   
        z-index: 9999;
    }
  
    .window {
        width: 100%;         
        height: calc(100vh - 107px);  
        z-index: 999;
        padding-top: 3px;
        border-top: 1px solid var(--windows-stroke-glass-specular, rgba(255, 255, 255, 0.40));  
    }
 
    header {
        margin-top: 0px;
        padding-top: 0px;  
        display: block;
        width: calc(100% - 17.5px);
        z-index: 1000;   
        height: 50px;
        background: transparent;
        border-top-left-radius: 500px;
        border-top-right-radius: 500px;     
        border-top: 0px solid transparent !important;  
    }

    .content {
        z-index: 9;
        height:  calc(100vh - 130px);   
        padding-top: 60px;
        padding-bottom: 3px;
    }
    
    .MyStudioContent {   
        width: calc(100vw -35px);   
        height: 100%;
        flex-wrap: nowrap;          
    }

    .navigationbar_ai { 
        z-index: 9999999999;
        width: 100%;
        height: 80px;
    }

    .logo_title {
        padding: 0px;
    }  

    .buttons {
        top :  27px;   
        right: 30px;
        text-align: center;
        align-items: center;
        gap: 8px;
    }

    .icon_coin {
        width: 28px;
        height: 28px;
    }

    .button_symbol{ 
        width: 25px;
        height: 25px;
    }
    .symbol {
        width: 25px;
        height: 25px;
    }

    .icon_outlinemenu{
        width: 24px;
        height: 24px;
    }
    
    .app_icon { 
        width: 36px;
        height: 36px;
    } 
    
    .imagegenerated_aiimage {
        width: 300px;
        height: 340px;  
    }

    .imagegenerated_frame {
        width: 300px;
        height: 300px;
    }
    
    .imagegenerated_imageai {
        width: 285px;
        height: 285px;  
        object-fit: cover; 
    }
     
    .messagebox { 
        min-height: 55px;  
        max-height: 100px;
        height: auto;
        padding:  10px 10px  10px  10px; 
        gap: 8px;  
        top :  calc(100vh- 170px);  
        width:  calc(100% - 20px);   
    }

    .messagebox_textarea {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1 0 0;
        align-self: stretch; 
        align-items: flex-start;
        min-height: 50px;  
        height: auto;
    }

    .messagebox_textarea_title {  
        font-size: 14px; 
        line-height: 20px;   
        height: 100%; /* Taşma sorununu önlemek için */
        resize: none; /* Kullanıcının boyut değiştirmesini engelle */
        overflow-y: auto; 
        box-sizing: border-box; /* Padding dahil */ 
        width:   100% ;   
        padding: 2px; 

    }

    .image_messagebox_left {
        width: 24px;
        height: 24px;
        margin-left:  5px;
    }

    .image_messagebox_right {
        width: 36px;
        height: 36px;
        margin-right:  5px;
    }

    .button_nostyle {
        width: 36px;
        height: 36px; 
    }

    .title_table_contents { 
        font-size: 14px; 
        font-weight: 400;
    }
    
    .symbol{ 
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;  
    }

    .ainame {
        font-size: 16px;
    }

    .userphoto { 
        width:  50px; 
    }

    .icons_avatar {
        width: 28px;
        height: 28px; 
    }
    
    .userprofileFoto_small{
        width: 28px;
        height: 28px;  
    }

    .logintextBlock { 
        width: 370px; 
    } 

    /* my studio */

    .uploadfileinput { 
        width: 100%;
        justify-content: space-between;
        align-items: stretch;
    
    }
    
    input[type="file"]::file-selector-button {
        background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
        background-blend-mode: color-dodge, lighten;
        color: white;
        padding: 10px 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-align: right;
    }

    .image-zoom-container   { 
        max-height: 450px; 
        max-width: 400px;
        min-width: 100px;
        align-items: center;
        text-align: center;
        height: 100%;
        border-radius: 32px;   
        border: 1px solid rgba(0, 0, 0, 0.10); 
    } 

    .zoom-image {
        max-height: 450px; 
        max-width: 400px;
        min-width: 100px;
        width: auto;
        border-radius: 32px;
        border: 1px solid rgba(0, 0, 0, 0.10);
      
    }

    .button_createpost_label {
        display: none; 
    }

    .button_favorites_label {
        display: none;
    } 

    .button_favorites { 
        height: 44px;
        padding: 0px 16px; 
    }

    .button_createpost{ 
        height: 40px;
        padding: 0px 10px; 
    }

    .button_back_appIcon {
        width: 28px;
        height: 28px;
    }
    
    .button_myposts_creations_label {
        display: none;
    }
    
    .button_myposts_creations { 
        height: 44px;
        padding: 0px 16px; 
    }

    .delete_button_Text {
        display: none;
    }

    .delete_button { 
        height: 44px;
        padding: 0px 16px; 
    }
 
    .table_mystudio {
        display: flex;
        padding: 0px 10px;
        flex-direction: column;
        align-items: center;
        gap : 10;
        flex: 1 0 0;
        align-self: center;
        padding-bottom: 20px; 
        justify-content: center;
      }

    .myuploads_creations { 
        padding: 24px 10px;
        gap: 8px;
        align-self: center;
        justify-content: center;
      
    }

    .aiimages_preview {
        display: flex; 
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;   
        align-self: center;
        justify-content: center;
        gap: 15px; 
        border-radius: 8px;  
        margin: auto;
      }

    .mc_titlebar { 
        width: calc(100vw - 130px);
    }

    .mc_title { 
        font-size: 16px; 
    }

    .myuploads_button_label {
        display: none;
    }

    .myuploads_button { 
        height: 44px;
        padding: 0px 25px; 
    }

    .photos_favorite { 
        width: 150px;
        height: 150px; 
        margin: auto;
    }

    .photos_favorite img {  
        max-width: 150px;
        max-height: 150px;
        width: auto; 
        height: auto;
        border-radius: 8px;
        
      }
      
    /* my creations*/

    
    .MyCreationContent {  
        width: calc(100vw - 45px);   
        height:  100%;  
        flex-wrap: nowrap;
    }

    .mystudioback_label { 
        font-size: 18px; 
    }
 
    .navbar_creations {   
        padding: 15px;
    }
    

    .table_mycreation {
        display: flex;
        margin: auto;
        flex-direction: column;
        align-items: center;  
        align-self: center;
        z-index: 5; 
        height: 100vh;  
        margin-bottom: 10px;
        padding-left: 10px;
        padding-right: 0px;
      
    }

    .infinite-scroll-container {
        height: 100%;   
        margin: auto;
        z-index: 3;  
        align-items: center;
        text-align: center;
    }

    .detailsTableLeft {
        height: calc(100vh - 350px);  
        width:  calc(100vw - 65px);  
        overflow-y: scroll;
        z-index: 6; 
        padding-left:  0px;
        margin-bottom: 5px;
        flex-direction: column;
        align-items: center;
        
    }

    .detailsTable { 
       margin: auto;
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 12px;    
        margin-bottom: 15px; 
        min-width: 370px;
    }

    .detailsTableLeft { 
        margin-bottom: 25px; 
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 10px;
    }

    .mycreationTable_Text  {
        font-size: 16px;
    }

    .mycreationTable_Text  {
        font-size: 16px;
    }
  
    .mycreationTable_boxes  { 
        padding: 9px 0px 10px 0px;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;
        border-radius: 16px; 
        min-width: 290px;
        min-height: 170px;
  
    }

    .mycreationTable_box  {
        width: 155px;
        min-height: 170px;
        height: auto;
        align-items: flex-start; 
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        margin: auto;
    }

    .mycreationTable_box_rectangle img {
        width: 150px;
        height: 150px; 
    }

    .corner_rounding {
        display: flex;
        align-items: center;
        flex-wrap: wrap; 
        gap: 10px;
        align-self: stretch;
        justify-content: center;
        flex-direction: row;
        border-radius: 8px; 
        height: 175px;     
        height: auto;
        width:  100%;
        margin: auto;
    }

    .mycreationTable_box_rectangle_Status{
        font-size: 12px;
        width: 124px;
    }
  
    .mycreationTable_box_rectangle_Text {
        font-size: 12px;
        width: 124px;
    }

    /* my studio details */
  
    .mystudioDetails_table  { 
        padding: 0px 0px;
        align-items: flex-start;
        gap: 12px;  
        min-height: calc(100% - 100px);
        flex-direction: row;
        flex-wrap: wrap;
    }

    .mystudioDetails_table_left { 
        padding: 0px 2px; 
        gap: 40px; 
        align-self: stretch;
    } 

    .table_left_textfield {
        display: flex;
        flex-direction: row;
        align-self: stretch;
        width: 365px;
        height: 40px;
        padding: 0px 8px 0px 20px; 
    }

    .textfield_text input{ 
        font-size: 15px; 
        width: 310px;
        height: 40px; 
        border: none;
        outline: none;
        
    }
    
    .selectbuttons {
        display: flex;
        width: 365px;  
        align-items: center; 
        height: 40px;
        padding: 0px 2px; 
    }

    .category_select   { 
        height: 30px;
        padding: 0px 0px 0px 0px; 
        font-size: 14px;
        font-weight: 400;
        line-height: 0px;
    }

    .category_select select:hover {
        height: 30px;
    } 

    .category_select.selected select {
         height: 30px;
    }

    option {
        font-size: 14px;
      }

    .textfield_big { 
        width: 365px;
        height: 70px; 
        margin-bottom: 30px; 
    }

    .textfield_big_text { 
        font-size: 14px;  
        width: 365px;
        height: 70px;
    }

    .mystudioDetails_titlebar {
        display: flex;
        padding: 15px 0px;
        margin-left: 0px;
        justify-content: center;
        align-items: center;
        align-self: center; 
        flex-direction: column;
        width: 100%;
    }

    .statusMyCreation {  
        margin-top:  -50px;
        text-align: center;  
        font-size: 14px; 
        font-weight: 600;
        line-height: 20px;  
        margin-left: -10px;
    }

    .requestBar {
        align-items: flex-start;
        width: 100%;
        margin-top: 20px; 
    }
    
    .requestText { 
        font-size: 15px; 
        font-weight: 600;
        line-height: 20px;   
    }
    
    .requestBar_big_cr {
        display: flex;  
        margin-top: 10px;
        padding: 10px;
        align-items: center; 
        margin-right: 10px;
      }
      
      .requestBar_big_textcr { 
        font-size: 15px;  
        line-height: 20px;   
        width: 100%; 
        border: none;
        outline: none; 
      }
     
    .mystudioDetails_title { 
        font-size: 16px; 
        font-weight: 600;
        line-height: 20px; 
    }

    .mystudioDetails_table_right {
        align-items: center;  
        justify-content: center;
        align-self: center; 
        width: 100%;
        padding: 5px 4px;   
        margin-right: 5px;
    }

    .mystudioDetails_table_AI_Preview {
        display: flex; 
        flex-direction: row;
        align-items: center;
        gap: 4px;
        border-radius: 16px;  
        height: 200px;  
        width:  100%;   
    }
 
    .mystudioDetails_table_corner_rounding { 
        align-items: center;   
        justify-content: center;
        margin: auto;
    }

    .mystudioDetails_table_corner_photobox { 
        width: 150px;
        height: 150px; 
    }
    
    .mystudioDetails_table_corner_img {
        width: 150px;
        height: 150px; 
    }

    .mystudioDetails_table_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
    }
    
    .mystudioDetails_table_header_titlebox {
        display: flex;
        width: 100%;
        padding: 9px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px; 
    }

    .table_header_description {
        display: none;
    }

    .table_header_title { 
        font-size: 16px; 
    }

    .button_createpost_appIcon {
        width: 24px;
        height: 24px;
    }
   

    .navbar {
        display: flex;
        flex :1;
        padding-top: 17px;
  
      
        flex-direction: row;  
        max-height: 120px;
        flex-wrap: wrap; 
    } 

    .mycreationText {
        display: none;
    }

    .mystudioDetails_right {
        display: flex; 
        padding-right: 1px;
        justify-content: flex-end;
        align-items: flex-end; 
    }

    .mystudioDetails_right_saveDraft { 
        height: 42px;
        padding: 0px 1px;
        justify-content: center;
        align-items: center;
        gap: 2px; 
    }

    .textfield_big_cr {
        display: flex;
        width: 96%;
        height: 70px;
        margin-left: 10px; 
        align-items: center;
    }
      
    .textfield_big_textcr { 
        font-size: 16px; 
        width: 100%;
        height: 70px; 
    }

    /* Admin */

    .navbar_Admin {
        display: flex;
        flex :1;
        padding-top: 17px;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: flex-start;
        flex-direction: row;  
        max-height: 170px;
        flex-wrap: wrap;
        gap:10px;
        width: 400px;
    } 

 
    .navbar_rightSpotlight {
        width: 400px;
    }

    .searchBox_SpotlightBar {
        width: 250px;
    }

     
    .tableCreationList {
        display: flex; 
        flex-direction: column; 
        align-items: center;
        flex: 1 0 0;
        align-self: stretch; 
        overflow-x: scroll;
        
    }

    .tableCreationRow {
        display: flex; 
        padding:  0px 14px;   
        justify-content: space-between;   
        padding-bottom: 5px; 
        gap : 20px;
    }
     
    .tableColumnRight {
        display: flex;   
        min-width: 400px;
        flex-direction: row;  
        justify-content: flex-start;  
    }
    
    .tableColumnLeft {
        display: flex;   
        width: 720px;
        flex-direction: row;
        align-items: flex-start ; 
    }
    .tableColumn {
         
        width: 130px; 
    }
    
    .tableColumnTitle {   
        width: 300px; 
    }
    .tableColumnCheck {
        width: 300px; 
   
    }

    .mycreationFilterBox {
        display: flex; 
        align-items: center;
        gap: 7px;
    }
 
    .multiselect-container {
        padding: 1px; 
    }

    .multiselect-container ::placeholder { 
        text-align: left; 
        font-size: 15px; 
        font-weight: 500;
        line-height: 20px;
    }
      
    .multiselect-container ::-ms-input-placeholder { 
        text-align: left;  
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .multiselect-chip {  
        border-radius: 4px;
        padding: 2px 8px;
        font-size: 10px;
        display: flex;
        align-items: center;
    }
  
    .multiselect-container .option {  
        width: 150px;  
        font-size: 12px;
    }
  
    .searchBox_Creation { 
        display: flex;
        width: 235px;
        height: 41px;
        padding: 0px 8px; 
        gap: 8px;
    }

    .Listin { 
        padding: 7px 5px; 
        width: 220px;  
        gap: 10px;
        margin-right: 10px;
    }
     
    .Listin select {   
        font-size: 15px; 
        line-height: 20px;  
        padding: 5px; 
        width: 200px;
    }
    
    .button_archive { 
        padding: 7px 5px;  
        width: 90px; 
        margin-right: 10px;
    }
    
    .button_archive select { 
        padding: 4px;  
        font-size: 15px; 
        font-weight: 500;
        line-height: 20px;    
        width: 80px;
    }
 
    .tableCreationDetailsBar {     
        width : 1224px;
        padding: 15px 0px;
        margin-left: 0px;
        overflow-x: scroll;
        margin-bottom: 30px;
    }

    .submissionText { 
        margin-left: 5px; 
        font-size: 16px; 
        font-weight: 600;
        line-height: 20px;   
    }

    .creationImages {
        display: flex;
        padding: 20px 20px;
        width: 98.8%; 
    }

    .CreationDetails_table_AI_Preview {
        display: flex; 
        flex-direction: row;
        align-items: center;
        gap: 4px;
        border-radius: 16px;  
        height: 200px;  
        width:  100%;    
    }

    .commentary_Column {
        display: flex;
        width: 99%;
        height: 250px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .commentary_Input {
        display: flex; 
        width: 99%;
        height: 200px;
        padding: 8px 20px;
        align-items: flex-start;
    }

    .commentary_Input textarea {  
        font-size: 16px;  
        line-height: 20px;   
        width: 99%;
        height: 200px;
        border: none;
        outline: none;
    }

    .custom-arrowS { 
        right: 40px;  
        top: 37px;   
    }
      
    .A_custom-arrowS { 
        left: 100px;  
        top:  93px;   
    }
       
    .A_custom-arrowC { 
        left: 210px;  
        top: 93px;   
    }

    .searchBox  {
        padding: 2px;
        width: 100px;
      }
      
    .multiselect-container .optionContainer {
        min-width: 130px;   
        z-index: 111;
    }
    
    .multiselect-container .optionListContainer {
        min-width: 130px; 
        z-index: 111;
    }

  
    /* Spotlight */

    .defaultwindowSpotLight { 
       z-index: 99;
    } 
      
    .windowSpotLight { 
        z-index: 999;
    }
      
    .contentSpotLight { 
        height: calc(100vh - 150px);  
    }

    .spotlightContent {  
       
        display: flex; 
        padding: 0px 0px;
        flex-direction: column;
        align-items: flex-start; 
        justify-content: flex-start;
        align-self: center;
        overflow-x: hidden;
        overflow-y: hidden; 
        height:  calc(100% - 10px);  
        width: calc(100vw - 35px);
        z-index: 7;
      }

    .middleBlock {
        
        height: calc(100vh - 200px); 
        
    }

    .mycreationTable_LeftText {  
        cursor : pointer;
        color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;  
    }  

    .mycreationTable_header1Sub { 
        display: flex;
        padding: 10px 5px;
        gap: 10px;
        align-items: flex-start;   
    }       

    .button_hide {
        display: flex;
        height: 30px;
        margin-top: 5px;
        padding: 0px 25px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 500px;
        background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
        background-blend-mode: color-dodge, lighten;
        z-index: 1121; 
    }

    .mystudioback_label { 
        font-size: 14px; 
    }

 
    
    .searchBox_Spotlight {
        display: flex;
        width: 255px;
        height: 44px;
        padding: 0px 8px;
        align-items: center;
        gap: 8px;
        border-radius: 100px;
        background-color: #000;
        background-blend-mode: luminosity, color-burn;
        box-shadow: 0px -0.5px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px -0.5px 1px 0px rgba(255, 255, 255, 0.25) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;
    }
    
    .search_text {  
        font-size: 12px; 
        line-height: 20px;   
    }
    
  
   
    .multiselect-container {
        width: 107px; 
        height: 40px;   
    }
       
    .searchWrapper {
        border-radius: 500px;
        background-color: #535454; 
        width: 107px;
        height: 40px;    
        text-align: center;
    }
    
    .search-wrapper { 
        border-radius: 500px;
        padding: 5px;  
        width: 107px;    
        text-align: center;
    } 

    .custom-arrowD { 
        right: 8px;  
    } 
      
    .custom-arrow { 
        right: 10px; 
    }

    .multiselect-without-border .option-label {
        font-size: 10px; 
    }
    .multiselect-without-border .searchWrapper input::placeholder {
        font-size: 12px;  
    }
    
    .multiselect-without-border .searchWrapper input {
        font-size: 12px;  
    }

    .selectFilterSpotlight {
        display: flex;
        max-width: 325px;  
        align-items: flex-start; 
        height: 41px; 
        justify-content: flex-start;   
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;   
    }

    .spotlightback_label {
        display: none;
    }
      
    .seeAllCreation { 
        width: 150px;
        height: 40px; 
        text-align: center;
        display: flex;
        gap : 25px;
        padding: 8px; 
        align-items: center;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.30);
        color: #FFF; 
        font-size: 12px;
        font-style: normal;
        font-weight: 500; 
      }

      .sidebar_Left {
        display: none;
      }
    
      .sidebar_Right {
          display: none;
      }

      .navbar_right {
        display: flex;
        flex-wrap: wrap-reverse;
        flex-direction: row; 
        justify-content: space-between;
       
        align-items: center;
        gap: 15px;
        width: 250px;
      }

 
      .MyCreationListContent {
        z-index: 99;
      }

      .tableCreationList { 
        min-height: calc(100vh - 300px);  
        z-index: 9;
        padding-bottom: 40px;
      }

      .profileInformationTable { 
        padding: 16px 10px; 
      }

      .profileInformationTableLeft { 
        padding: 0px 0px 56px 0px; 
        gap: 42px;
         
      }

      .profileInformationTableLeftTextField {
        width: 375px;
        padding: 0px 0px 0px 5px;
      }

      .profiletextfield_text input { 
        width: 330px; 
      }

      .profileInformationTableRightTextField {
        width: 370px;
        padding: 16px 5px;
             
      }

      .profileInformationTableRight {
        width: 400px;  
        padding: 8px 10px;
        flex-direction: column;
        align-items: center;
        text-align: center; 
        gap: 8px;
      }
      
      .navbar_spotlight {
        
        justify-content: flex-start; 
        gap: 10px;  
       
      } 
      
}

@media (min-width : 635px) and (max-width:800px) {
    .navbar_right {
        display: flex;
        flex-wrap: wrap-reverse;
        flex-direction: row; 
        justify-content: space-between; 
        align-items: center;
        gap: 15px;
        width: 300px;
    }

    .navbar_rightSpotlight { 
        width: 500px;
    }

    .navbar_Admin {
        display: flex;
        flex :1;
        padding-top: 17px;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: flex-start;
        flex-direction: row;  
         height: 170px;
        flex-wrap: wrap;
        gap:10px;
        width: 500px;
    } 
 
    .A_custom-arrowS { 
        right:  120px;  
        top:  93px;   
    }
       
    .A_custom-arrowC { 
        right: 290px;  
        top: 93px;   
    }
    .sidebar_Left {
        display: none;
      }
    
      .sidebar_Right {
          display: none;
      }
      .navbar_spotlight {
        
        justify-content: flex-start; 
        gap: 10px;  
       
      } 
}


@media (min-width : 801px) and (max-width: 1390px) {
    .navbar_right {
        display: flex;
        flex-wrap: wrap-reverse;
        flex-direction: row; 
        justify-content: space-between; 
        align-items: center;
        gap: 15px;
        width: 800px;
    }

    .navbar_rightSpotlight {
        display: flex; 
        flex-direction: row; 
        justify-content: space-between; 
        align-items: center;
        gap: 15px;
        width: 600px;
    }

    .navbar_Admin {
        display: flex;
        flex :1;
        padding-top: 17px;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: flex-start;
        flex-direction: row;  
         height: 170px;
        flex-wrap: wrap;
        gap:10px;
        width: 800px;
    } 

 
    .A_custom-arrowS { 
        right:  60px;  
        top:  93px;   
    }
       
    .A_custom-arrowC { 
        right: 230px;  
        top: 93px;   
    }

    .sidebar_Left {
        display: none;
      }
    
    .sidebar_Right {
          display: none;
    }
    .navbar_spotlight {
        
        justify-content: flex-start; 
        gap: 10px;  
       
      } 
}


@media (min-width : 550px) and (max-width:1397px) {
    .tableCreationList {
        display: flex; 
        flex-direction: column; 
        align-items: center;
        flex: 1 0 0;
        align-self: stretch;
     
        overflow-x: scroll;
    }

    .tableCreationRow {
        display: flex; 
        padding:  0px 14px;   
        justify-content: space-between;   
        padding-bottom: 5px;
     
        gap : 20px;
    }
    .tableColumnRight {
        display: flex;   
        min-width: 600px;
        flex-direction: row;  
        justify-content: flex-start;  
    }

    .tableColumnLeft {
        display: flex;   
        width: 720px;
        flex-direction: row;
        align-items: flex-start ; 
    }
    
    .tableColumn {
         
        width: 130px; 
    }
    
    .tableColumnTitle {   
        width: 300px; 
    }
    .tableColumnCheck {
        width: 300px; 
   
    }
 
    .navbar_rightSpotlight {
        width: 600px;
    }

    .navbar_Admin { 
      width: 800px;
    } 

 
    .navbar_right {
      
        width: 600px;
    }

}
