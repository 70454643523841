.alert {
    display: flex;
    width: 320px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 32px;
    border: 1.4px solid rgba(255, 255, 255, 0.40);
    background: rgba(128, 128, 128, 0.50);
    background-blend-mode: luminosity;

    /* Blur */
    backdrop-filter: blur(50px);
}
.alertIconbox{
    display: flex;
    width: 100%; 
    flex-direction: row; 
    gap: 10px;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    
}

.alertapIcon {
    width: 52px;
    height: 52px;
}
.alertTitleBox {
    display: flex;
    width: 270px;
    padding: 8px 16px;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}
.alertTitle {
    padding-top: 12px;
    color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 141.176% */
    height: 52px;
     
}


.alertText {
    align-self: stretch;
    color: var(--Text-Secondary, rgba(255, 255, 255, 0.23));
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
}

.alertSeparator {
    height: 1px;
    align-self: stretch;
    border-radius: 100px;
    background: var(--Separators-Separator, linear-gradient(0deg, rgba(94, 94, 94, 0.15) 0%, rgba(94, 94, 94, 0.15) 100%), rgba(255, 255, 255, 0.07));
    background-blend-mode: color-dodge, lighten;
}

.alertlgotIt{
    color: var(--Text-Primary, rgba(255, 255, 255, 0.96));
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.alertlgotItRed{
    color: #FF453A;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}